<script>
import Layout from "../../layouts/main";
import StudentIndexLayout from "../../layouts/studentIndexLayout";
import errorHandler from "@/helpers/errorHandler";
import { required, helpers } from "vuelidate/lib/validators";
import Swal from 'sweetalert2';
let phoneNumberRegex = helpers.regex('phoneNumberRegex',/^01[0-2,5]{1}[0-9]{8}$/)

/**
 * Starter component
 */
export default {
  page: {
    title: "Search Student",
  },
  head() {
    return {
      title: `Students-search`,
    };
  },
  validations: {
    phone: {
      required,
      phoneNumberRegex
    },
    code: {
      required,
    },
  },
  components: {
    Layout,
    StudentIndexLayout
  },
  methods: {
    searchStudent() {
      this.submitted = true;
      this.$v.phone.$touch();
      if (this.$v.$invalid.phone) {
        return;
      } else {
        this.$store.dispatch("student/searchStudentByPhone", '2'+this.phone).then( token =>{
            Swal.fire({
              icon: 'success',
              title: `<h3><img src="${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${token.user.image}" alt="contact-img" title="contact-img" class="avatar-xl rounded-circle mr-2"/>` + token.user.name + '</h3>',
              html: `
                    <table class="table table-borderless table-sm">
                        <tbody class="row">
                            <tr class="col-md-6">
                            <th scope="row">Name:</th>
                            <td>
                                ${token.user.name}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Phone:</th>
                            <td >
                                ${ token.user.phone_number}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Stage:</th>
                            <td>
                                ${ token.educational_year.number + ' - ' + token.educational_year.description }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">School:</th>
                            <td>
                                ${ token.school}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 1:</th>
                            <td>
                                ${ token.phone_no_1}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 2:</th>
                            <td>
                                ${ token.phone_no_2}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Activated:</th>
                            <td>
                                ${ token.status}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Balance:</th>
                            <td>
                                ${ token.balance }
                            </td>
                            </tr>
                        </tbody>
                        </table>
              `,
              showCancelButton: true,
              confirmButtonText: 'Show student'
              }).then( result => {
                if(result.isConfirmed){
                    this.$router.push(`/students/${token.stud_id}/details`);
                }
              });
            }).catch((error) => {
              errorHandler.methods.error(error);
        });
      }
    },
    searchStudentCode() {
      this.submitted = true;
      this.$v.code.$touch();
      if (this.$v.$invalid.code) {
        return;
      } else {
        this.$store.dispatch("student/searchStudentByCode", this.code).then( token =>{
            Swal.fire({
              icon: 'success',
              title: `<h3><img src="${process.env.VUE_APP_BASE_URL}e95c2c2dd3ef780c269f75ee3286df1c418bc016/${'profile_images'}/${token.user.image}" alt="contact-img" title="contact-img" class="avatar-xl rounded-circle mr-2"/>` + token.user.name + '</h3>',
              html: `
                    <table class="table table-borderless table-sm">
                        <tbody class="row">
                            <tr class="col-md-6">
                            <th scope="row">Name:</th>
                            <td>
                                ${token.user.name}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Phone:</th>
                            <td >
                                ${ token.user.phone_number}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Stage:</th>
                            <td>
                                ${ token.educational_year.number + ' - ' + token.educational_year.description }
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">School:</th>
                            <td>
                                ${ token.school}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 1:</th>
                            <td>
                                ${ token.phone_no_1}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Parent phone 2:</th>
                            <td>
                                ${ token.phone_no_2}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Activated:</th>
                            <td>
                                ${ token.status}
                            </td>
                            </tr>
                            <tr class="col-md-6">
                            <th scope="row">Balance:</th>
                            <td>
                                ${ token.balance }
                            </td>
                            </tr>
                        </tbody>
                        </table>
              `,
              showCancelButton: true,
              confirmButtonText: 'Show student'
              }).then( result => {
                if(result.isConfirmed){
                    this.$router.push(`/students/${token.stud_id}/details`);
                }
              });
            }).catch((error) => {
              errorHandler.methods.errorMessage(error);
        });
      }
    },
  },
  data: () => ({
    submitted:false,
    phone: '',
    code: '',
  })
};
</script>

<template>
  <Layout>
    <StudentIndexLayout>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" @submit.prevent="searchStudent">
                    <div class="form-group mb-3">
                    <label for="Phoneid">Phone</label>
                    <input
                        class="form-control"
                        v-model="phone"
                        type="text"
                        id="phoneid"
                        placeholder="Enter student phone number"
                        :class="{ 'is-invalid': submitted && $v.phone.$error }"
                    />
                    <div
                        v-if="submitted && $v.phone.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.phone.required">Phone is required.</span>
                    </div>
                    <div
                        v-if="submitted && $v.phone.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.phone.phoneNumberRegex">Please enter a phone.</span>
                    </div>
                    </div>
                    <div class="form-group mb-0 text-center">
                    <button class="btn btn-success" type="submit">
                        <i class="fas fa-search"></i>
                        Search
                    </button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <form action="#" @submit.prevent="searchStudentCode">
                    <div class="form-group mb-3">
                    <label for="Phoneid">ID</label>
                    <input
                        class="form-control"
                        v-model="code"
                        type="text"
                        id="code"
                        placeholder="Enter student ID"
                        :class="{ 'is-invalid': submitted && $v.code.$error }"
                    />
                    <div
                        v-if="submitted && $v.code.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.code.required">Code is required.</span>
                    </div>
                    </div>
                    <div class="form-group mb-0 text-center">
                    <button class="btn btn-success" type="submit">
                        <i class="fas fa-search"></i>
                        Search
                    </button>
                    </div>
                </form>
            </div>
        </div>
    </StudentIndexLayout>
  </Layout>
</template>
